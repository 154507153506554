export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    homeBannerSlider();
  },
};

$(window).on('resize', function () {
  $('.home-banner-section .banner-wrp').flickity(
    'resize'
  );
});

$(window).on('load', function () {
  $('.home-banner-section .banner-wrp').flickity(
    'resize'
  )
});

function homeBannerSlider() {

  let $homeBannerSlider = $('.home-banner-section .banner-wrp');

  if ($homeBannerSlider.length > 0) {

    $homeBannerSlider.flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: 'center',
    });
  }

  $('.message-section').prepend($('.flickity-page-dots'));
}