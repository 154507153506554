import 'lightcase/src/js/lightcase.js';
import 'select2/dist/js/select2.min.js';
import 'flickity/dist/flickity.pkgd.min.js';
import { Player } from 'shikwasa';

var shrinkHeader = 5;

export default {
  init() {
    // JavaScript to be fired on all pages
    // search page search input functionalities
    $('.search-form .search-field').on('input', function(){
      $(this).parents('.search-form').addClass('show');

      if(!$.trim(this.value).length) { // zero-length string AFTER a trim
        $(this).parents('.search-form').removeClass('show');
      }
    });

    $('.clear-icon').on('click', function(){
      $('.search-form .search-field').val('');
      $('.search-form').removeClass('show');
    });

    // Add smooth scrolling to all links
    $('a').on('click', function() {

      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== '') {
        // Prevent default anchor click behavior
        // event.preventDefault();

        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
          scrollTop: $(hash).offset().top,
        }, 800, function(){

          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        });
      } // End if
    });

    sectinalHeadsSlider();
    gallerySectionSlider();
    cardSectionSlider();
    fullWidthSliderSectionSlider();
    // mobileMenuAccordion();
    ourPrincipals();
    ourVicePrincipals();
    headmistressSlider();
    ourSports();
    wrapButtons();
  },
  finalize() {
    jQuery('.form-dropdown').select2({
      dropdownParent: $('.select2-wrapper'),
    });

    jQuery('a[data-rel^=lightcase]').lightcase({
      slideshowAutoStart: false,
    });

    $('header .mobile-menu').click(function(){
      $('body').toggleClass('mobile-menu-active');
    });

    if (window.innerWidth >= 992) {
      $(document).ready(function() {
        $('.staff-members-cards').slice(0, 9).show();
        if ($('.staff-members-cards:hidden').length != 0) {
            $('#loadMore').show();
        }
        $('#loadMore').on('click', function(e) {
            e.preventDefault();
            $('.staff-members-cards:hidden').slice(0, 3).slideDown();
            if ($('.staff-members-cards:hidden').length == 0) {
                $('#loadMore').text('No More to view').fadeOut('slow');
            }
        });
      })
    }

    // Getting Link from DOM
    let schoolSong = $('#schoolSongLink').val();

    if (schoolSong != undefined) {
      // Creating the Audio Player
      new Player({
        container: () => document.querySelector('.shikwasa-school-song-section'),
        audio: {
          title: 'School Song',
          artist: '',
          cover: '/wp-content/uploads/2023/04/music-player-image.png',
          src: schoolSong,
        },
      });
    }

    // Getting Link from DOM
    let collegeHymn = $('#collegeHymnLink').val();

    if (collegeHymn != undefined) {
      // Creating the Audio Player
      new Player({
        container: () => document.querySelector('.shikwasa-college-hymn-section'),
        audio: {
          title: 'College Hymn',
          artist: '',
          cover: '/wp-content/uploads/2023/04/music-player-image.png',
          src: collegeHymn,
        },
      });
    }

    // gallerySectionSlider();
    cardSectionSlider();
    wrapButtons();
    fullWidthSliderSectionSlider();
    mobileMenuAccordion();
    ourPrincipals();
    ourVicePrincipals();
    headmistressSlider();
    ourSports();
    slideUpMenuOption();
  },
};

$(window).on('resize', function () {
  $('.gallery-section .gallery-wrapper, .cards-section .cards-wrp, .card-slider-section .slider-enable, .principals-section .date-wrp, .principals-section:not(.vice-principals-section, .headmistress-section) .slider-content, .vice-principals-section .date-wrp, .vice-principals-section .slider-content, .headmistress-section .date-wrp, .our-sports-section .sports-items-wrp').flickity(
    'resize'
  );

  // loadMoreStaff();
});

// $(window).on('load', function () {
//   $('.gallery-section .gallery-wrapper, .cards-section .cards-wrp, .card-slider-section .slider-enable, .principals-section .date-wrp, .principals-section:not(.vice-principals-section, .headmistress-section) .slider-content, .vice-principals-section .date-wrp, .vice-principals-section .slider-content, .headmistress-section .date-wrp, .our-sports-section .sports-items-wrp').flickity(
//     'resize'
//   );
// });

$(window).on('load', function () {
  $(document).ready(function() {
    $('.gallery-section .gallery-wrapper, .cards-section .cards-wrp, .card-slider-section .slider-enable, .our-principals-section .date-wrp, .our-principals-section .slider-content, .vice-principals-section .date-wrp, .vice-principals-section .slider-content, .headmistress-section .date-wrp, .our-sports-section .sports-items-wrp').flickity(
      'resize'
    );
   });

  // loadMoreStaff();
});

$(window).scroll(function () {
  var scroll = getCurrentScroll();
  console.log(scroll);
  if (scroll >= shrinkHeader) {
    $('body, .header-container').addClass('sticky-header');
  } else {
    $('body, .header-container').removeClass('sticky-header');
  }
});

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}

//wrap all buttons with a wrapper to handle responsive - This works with "Add Buttons" feature in the wp editor
function wrapButtons() {
  $('.wrap-btns').each(function( ) {
    var wrapper = $(this).find('.btn').unwrap();

    wrapper.wrapAll( '<div class="button-wrapper" />');
  });
}
//wrap all buttons with a wrapper to handle responsive - This works with "Add Buttons" feature in the wp editor

function mobileMenuAccordion() {
  $('.header .menu-item-has-children:not(.nav-btn)').each(function() {
    $(this).find('> a').on('click', function(e){
      if(!window.matchMedia('(min-width: 1200px)').matches) {
        e.preventDefault();
        e.stopPropagation();
        $(this).parents('.menu-item-has-children').toggleClass('dropdown-active');
        $(this).parents('.menu-item-has-children').siblings().removeClass('dropdown-active');
        $(this).parents('.menu-item-has-children').siblings().children('.sub-menu').slideUp();
        $(this).parents('.menu-item-has-children').children('.sub-menu').slideToggle();
      } else {
        $('.header .menu-item-has-children a').unbind('click');
        $('.menu-item-has-children').siblings().removeClass('dropdown-active');
      }
    });
  });
}

function gallerySectionSlider() {
  let $gallerySection = $('.gallery-section .gallery-wrapper');

  if ($gallerySection.length > 0) {
    var cellAlignValue = ''

    if (matchMedia('screen and (max-width: 768px)').matches ) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $gallerySection.flickity({
      contain: false,
      pageDots: false,
      prevNextButtons: true,
      adaptiveHeight: false,
      wrapAround: false,
      watchCSS: true,
      cellAlign: cellAlignValue,
      initialIndex: 3,
    });
  }
}

function cardSectionSlider() {

  let $cardSectionSlider = $('.cards-section .cards-wrp');

  if ($cardSectionSlider.length > 0) {

    $cardSectionSlider.flickity({
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true,
      watchCSS: true,
      cellAlign: 'center',
    });
  }
}

function fullWidthSliderSectionSlider() {
  // get editor anchor, make it a span and wrap the item with the href of it
  $('.full-width-slider-section .slider-item-wrp .btn').each(function() {

    $(this).closest('.slider-item-wrp').wrap('<a href="'+ $(this).attr('href') +'" />');

    $(this).replaceWith('<span class="'+ $( this ).attr('class') +'">' + $( this ).text() + '</span>');
  });
  // get editor anchor, make it a span and wrap the item with the href of it

  let $cardSlider = $('.full-width-slider-section .slider-wrp .row');
  let $cardSliderItems = $cardSlider.children();

  var cellAlignValue = '',
      SliderItemLength;
  if (matchMedia('screen and (max-width: 767px)').matches ) {
    cellAlignValue = 'center';
    SliderItemLength = $cardSliderItems.length > 1;
  } else {
    cellAlignValue = 'left';
    SliderItemLength = $cardSliderItems.length > 4;
  }

  if (SliderItemLength) {
    $cardSlider.addClass('d-block');
    $cardSlider.flickity({
      contain: false,
      pageDots: true,
      prevNextButtons: true,
      adaptiveHeight: false,
      wrapAround: true,
      cellAlign: cellAlignValue,
    });
  } else {
    $cardSlider.flickity('destroy');
    $cardSlider.removeClass('d-block');
  }
}

function ourPrincipals() {
  let $principalDatesSlider = $('.principals-section:not(.vice-principals-section, .headmistress-section) .date-wrp');
  let $principalContentSlider = $('.principals-section:not(.vice-principals-section, .headmistress-section) .slider-content');

  $principalDatesSlider.flickity({
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
    cellAlign: 'center',
    asNavFor: '.principals-section:not(.vice-principals-section, .headmistress-section) .slider-content',
    initialIndex: 3,
  });

  $principalContentSlider.flickity({
    adaptiveHeight: true,
    pageDots: false,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: 'center',
    initialIndex: 3,
  });

  $('.principals-section:not(.vice-principals-section, .headmistress-section) .slider-content .flickity-button').appendTo($('.principals-section:not(.vice-principals-section, .headmistress-section) .date-wrp-outter'));
}

function ourVicePrincipals() {
  let $vicePrincipalDatesSlider = $('.vice-principals-section .date-wrp');
  let $vicePrincipalContentSlider = $('.vice-principals-section .slider-content');

  $vicePrincipalDatesSlider.flickity({
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
    cellAlign: 'center',
    asNavFor: '.vice-principals-section .slider-content',
    initialIndex: 3,
  });

  $vicePrincipalContentSlider.flickity({
    adaptiveHeight: true,
    pageDots: false,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: 'center',
    initialIndex: 3,
  });

  $('.vice-principals-section .slider-content .flickity-button').appendTo($('.vice-principals-section .date-wrp-outter'));
}

function headmistressSlider() {
  let $headmistressDatesSlider = $('.headmistress-section .date-wrp');

  $headmistressDatesSlider.flickity({
    pageDots: false,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: 'center',
    initialIndex: 3,
  });
}

function ourSports() {

  let $ourSportsSlider = $('.our-sports-section .sports-items-wrp');

  $ourSportsSlider.flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: 'left',
  });
}

function sectinalHeadsSlider() {

  let $headSectionSlider = $('.sectional-heads-section .sectional-heads-slider-wrp');

  if ($headSectionSlider.length > 0) {

    $headSectionSlider.flickity({
      pageDots: false,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: 'center',
    });
  }
}

// Header Scroll up Option
function slideUpMenuOption() {
  // if ($('.header').hasClass('enable-scroll-up')) {
  //   // var prevScrollpos = window.pageYOffset;
  //   window.onscroll = function() {
  //     var currentScrollPos = window.pageYOffset;
  //     // console.log(currentScrollPos);
  //     if (currentScrollPos > 250 ) { //greater than 2 times the height of the menu bar mentioned below
  //       $('.header').css('position', 'sticky');
  //       $('.header').css('background-color', '#f9e7d9');
  //       $('.home-banner-section').css('z-index', '3');
  //       $('.banner-section').css('z-index', '3');
  //       // if (prevScrollpos > currentScrollPos) {
  //       //   $('.header').css('top', '0');
  //       // } else {
  //       //   $('.header').css('top', '-180px'); //minus the maximum height of the menu bar can be
  //       // }
  //       // prevScrollpos = currentScrollPos;
  //     } else if (currentScrollPos < 150) {
  //       $('.home-banner-section').css('z-index', '2');
  //       $('.banner-section').css('z-index', '2');
  //       $('.header').css('position', 'relative');
  //       $('.header').css('background-color', '#fef4ec');
  //     }
  //   }
  // }
}
// Header Scroll up Option

// Load more staff members